<template>
  <v-app>
    <main>
      <div>
        <v-row
          align="center"
          justify="center"
        />
        <br>
        <br>
        <v-card
          class="my-base-vcard-style"
          elevation="0"
        >
          <br>
          <v-container fluid>
            <v-icon
              class="success-icon"
              color="#C62828"
              size="60"
            >
              mdi-close-circle-outline
            </v-icon>
            <h1 class="title-style">
              Connection Failed
            </h1>
            <p class="msg-style">
              Please make sure that the url is valid or check your connection.
            </p>
          </v-container>
          <br>
          <br>
        </v-card>
        <dashboard-core-footer />
      </div>
    </main>
    <centre-spinner
      :loading="loading"
    />
  </v-app>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'UserQrCodeSuccessPage',
    components: {
      'centre-spinner': spinner,
      DashboardCoreFooter: () => import('../dashboard/components/core/Footer'),
    },
    data () {
      return {
        loading: false,
      };
    },
    mounted () {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 2000);
    },
  };
</script>
<style scoped>
.my-base-vcard-style {
    text-align: center;
    margin-left: 25px;
    margin-right: 25px;
    border: 3px solid #F5F5F5;
    margin-top: 100px;
}
.title-style {
    color: #37474F;
    font-family: 'Times New Roman', Times, serif;
}
.msg-style {
    font-size: 16px;
    color: #37474F;
    font-family: 'Times New Roman', Times, serif;
}
</style>
